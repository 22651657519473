

// images start
import CampusPulses from "../assets/images/GYP-LOGO/CampusPulses.png";
import INTellect from "../assets/images/GYP-LOGO/INTellect.png";
import NextWave from "../assets/images/GYP-LOGO/NextWave.png";
import ApexStage from "../assets/images/GYP-LOGO/ApexStage.png";
import Literature from "../assets/images/GYP-LOGO/Literature.png";
import History from "../assets/images/GYP-LOGO/History.png";
import Comparative from "../assets/images/GYP-LOGO/Comparative.png";
import TheProfessor from "../assets/images/GYP-LOGO/TheProfessor.png";
// images end
export const sectionList = [
  {
    value: 1,
    img: CampusPulses,
    color: "#F4470C",
  },
  {
    value: 2,
    img: INTellect,
    color: "#7642EA",
  },
  {
    value: 3,
    img: NextWave,
    color: "#1A41E8",
  },
  {
    value: 4,
    img: ApexStage,
    color: "#E23737",
  },
  {
    value: 5,
    img: Literature,
    color: "#FFAB24",
  },
  {
    value: 6,
    img: History,
    color: "#EF8D21",
  },
  {
    value: 7,
    img: Comparative,
    color: "#4A37DD",
  },
  {
    value: 8,
    img: TheProfessor,
    color: "#1879B3",
  },
]

export const tabList =  [
  { label: "Campus", name: "Campus" },
  { label: "Business", name: "Business" },
  { label: "Literature", name: "Literature" },
  { label: "Thinking", name: "Thinking" },
  { label: "Intellect", name: "Intellect" },
  { label: "Apex", name: "Apex" },
  { label: "History", name: "History" },
  { label: "PAC", name: "PAC" },
];