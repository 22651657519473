export function isMobile() {
    if (
        window.navigator.userAgent.match(
            /(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i
        )
    ) {
        return true;
    }
    return false;
}

export const TagColorList = [

    {
        color: "#1A202C",
        background: "#f2fdf6",
    },
    {
        color: "#1A202C",
        background: "#f4f0ff",
    },
    {
        color: "#1A202C",
        background: "#f2fdf6",
    },
    {
        color: "#1A202C",
        background: "#f9f9f9",
    },

    {
        color: "#1A202C",
        background: "#f2fdf6",
    },
    {
        color: "#1A202C",
        background: "#f4f0ff",
    },
    {
        color: "#1A202C",
        background: "#f2fdf6",
    },
    {
        color: "#1A202C",
        background: "#f9f9f9",
    },
];