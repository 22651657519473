<template>
  <div class="base-card-container">
    <div class="header">
      <div :style="{ backgroundImage: `url(${titleIcon})` }" class="icon"></div>
      <div v-if="showOpeBtn" class="operate el-dropdown">
        <el-dropdown @command="handlerCommand">
          <span class="el-dropdown-link">
            <i class="el-icon-more rotate90"></i>
          </span>
          <el-dropdown-menu slot="dropdown">
            <template v-for="(item, index) in dropDownOptions">
              <el-dropdown-item :command="item.command" :key="index">{{
                item.remark
              }}</el-dropdown-item>
            </template>
          </el-dropdown-menu>
        </el-dropdown>
      </div>
      <el-tag v-if="showStatusTag" :type="statusTag[content_status]">{{
        statusText
      }}</el-tag>
    </div>
    <div class="content" @click="goDetail()">
      <div
        :style="{ backgroundImage: `url(${coverPicture})` }"
        class="pic"
      ></div>
      <div class="text-content">
        <div class="title">{{ title }}</div>
        <template>
          <div v-if="member_status === 'invited'" class="invite-desc">
            {{
              `You are invited by ${inviter_name} to co-authoring this article.
            Click “Join” to accept this invitation and join this team or click
            “Reject” to decline.`
            }}
          </div>
          <div v-else class="abstract" v-html="ellipseContent"></div>
        </template>

        <div class="time-content">
          <div class="time">Last Updated: {{ formatTime }}</div>
          <template>
            <div v-if="member_status === 'invited'" class="footer-btn">
              <el-button
                type="primary"
                class="btn"
                @click.stop="handleInviteBtn('reject')"
                >Reject</el-button
              >
              <el-button
                type="primary"
                class="btn"
                @click.stop="handleInviteBtn('join')"
                >Join</el-button
              >
            </div>
            <div v-else class="tag">
              <div v-if="tags.length" class="tags-container">
                <div
                  v-for="(tag, index) in tags.filter((i) => !!i)"
                  class="tag"
                  :key="index"
                  :style="{
                    color: TagColorList[index].color,
                    background: TagColorList[index].background,
                  }"
                >
                  <div class="tag-text">{{ tag }}</div>
                </div>
              </div>
            </div>
          </template>
        </div>
      </div>
    </div>
    <el-dialog
      :visible.sync="showReviewArticleDialog"
      width="560px"
      :modal="true"
      title="Review Article"
      :modal-append-to-body="true"
    >
      <div class="customDialogContent">
        <p
          style="
            color: #333;
            font-size: 16px;
            line-height: 24px;
            font-family: GravityBold;
            margin-top: -15px;
            margin-bottom: 10px;
          "
        >
          Please review for article :
          <span style="color: var(--c-primary)">{{ title }}</span>
        </p>
        <el-form
          ref="reviewForm"
          :model="reviewArticle"
          :rules="rules"
          label-position="top"
          label-width="150px"
          status-icon
        >
          <el-form-item prop="new_status" label="Select Status">
            <el-select
              v-model="reviewArticle.new_status"
              placeholder="Please select"
            >
              <el-option value="pass" label="Pass"></el-option>
              <el-option value="refused" label="Refused"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item prop="review_content" label="Input Content">
            <el-input
              v-model="reviewArticle.review_content"
              type="text"
              placeholder="Please input content "
            />
          </el-form-item>
        </el-form>
        <div
          style="
            display: flex;
            flex-direction: column;
            align-items: center;
            margin-top: 10px;
          "
        >
          <el-button type="primary" class="btn" @click="doSubmitReviewResult">
            Confirm</el-button
          >
        </div>
      </div>
    </el-dialog>

    <el-dialog
      :title="
        inviteStatus === 'join'
          ? 'Accept Co-authoring Invitation'
          : 'Reject Co-authoring Invitation'
      "
      :visible.sync="inviteDialog"
      width="60%"
    >
      <div class="label">
        {{
          inviteStatus === "join"
            ? "Do you confirm to accept this invitation and co-authoring this article?"
            : "Are you sure you want to decline this co-authoring invitation?"
        }}
      </div>

      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="handleCancel">Cancel</el-button>
        <el-button type="primary" @click="handleConfirm">Confirm</el-button>
      </span>
    </el-dialog>
    <unsupport-modal type="invite" v-model="unsupportVisible"></unsupport-modal>
  </div>
</template>

<script>
import { TagColorList } from "../utils/common";
import picPlaceholder from "@/assets/images/img-placeholder.png";
import { getUserId, getUserType } from "../utils/store";
import { sectionList } from "../utils/commonList";
import unsupportModal from "./unsupportModal.vue";
import {
  submitReview,
  reviewArticle,
  removeArticle,
  checkSubscriptionStatus,
  removeArticleMember,
  joinArticle,
} from "../api/index";
export default {
  components: {
    unsupportModal,
  },
  props: {
    _id: Object,
    title: String,
    content: String,
    create_time: Number,
    icon: String,
    tags: Array,
    cover_address: String,
    content_status: String,
    type: [Number, String],
    showOpeBtn: {
      type: Boolean,
      default: true,
    },
    showStatusTag: {
      type: Boolean,
      default: false,
    },
    member_status: String,
    member_id: String,
    inviter_name: String,
  },

  data() {
    return {
      user_type: getUserType(),
      TagColorList,
      dropDownOptions: [
        { command: "Edit", remark: "Edit" },
        { command: "Delete", remark: "Delete" },
      ],
      reviewArticle: {
        article_title: "",
        article_id: "",
        new_status: "",
        review_content: "",
        reviewer_id: getUserId(),
      },
      rules: {
        new_status: [
          { required: true, message: "Please select", trigger: "blur" },
        ],
        review_content: [
          { required: true, message: "Please input", trigger: "blur" },
        ],
      },
      showReviewArticleDialog: false,
      statusTag: {
        review: "danger",
        refuesd: "warning",
        pass: "success",
      },
      sectionList: sectionList,
      inviteDialog: false,
      inviteStatus: null,
      unsupportVisible: false,
    };
  },

  computed: {
    formatTime() {
      return this.$dayjs(this.$props.create_time * 1000).format(
        "HH:mm:ss MM/DD/YYYY"
      );
    },
    ellipseContent() {
      const text = this.$props.content
        ? this.$props.content.replace(/<[^>]*>/g, "")
        : "";
      if (text.length > 300) {
        return text.slice(0, 300) + "...";
      } else {
        return text;
      }
    },
    coverPicture() {
      if (this.$props.cover_address) {
        return this.$props.cover_address;
      } else {
        return picPlaceholder;
      }
    },
    titleIcon() {
      const _list = this.sectionList.filter(
        (i) => i.value === this.$props.type
      )[0];
      return _list && _list.img;
    },
    statusText() {
      return this.$props.content_status === "review"
        ? "Under Review"
        : this.$props.content_status === "refuesd"
        ? "Rejected"
        : "Published";
    },
  },

  mounted() {
    const list = ["Submit", "Draft"];
    const action = list.find((i) => i === this.$props.content_status);
    if (action) {
      this.dropDownOptions.push({ command: action, remark: action });
    }
  },

  methods: {
    review() {
      this.reviewArticle = {
        article_title: this.$props.title,
        article_id: this.$props._id.$id,
        new_status: "",
        review_content: "",
        reviewer_id: getUserId(),
      };
      this.showReviewArticleDialog = true;
    },
    draft() {
      this.$message.success("订阅功能晚一点开发...");
      submitReview(this.$props._id.$id).then((res) => {
        let result = res.data;
        if (result.code == 0) {
          this.$message.success("Submit success~");
          this.$emit("refreshData");
        } else {
          this.$message.warning(result.msg);
        }
      });
    },
    doSubmitReviewResult() {
      this.$refs.reviewForm.validate((val) => {
        if (val) {
          reviewArticle(this.reviewArticle).then((res) => {
            let result = res.data;
            if (result.code == 0) {
              this.$message.success("Submit success~");
              this.showReviewArticleDialog = false;
              this.$emit("refreshData");
            } else {
              this.$message.warning(result.msg);
            }
          });
        }
      });
    },
    handlerCommand(command) {
      console.log(this.$props);
      switch (command) {
        case "Edit":
          this.$router.push({
            path: "/edit",
            query: {
              id: this.$props._id.$id,
              setionType: this.$props.type,
            },
          });
          break;
        case "Delete":
          this.handleDelete();
          break;
        case "Submit":
          this.review();
          break;
        case "draft":
          this.draft();
          break;
      }
    },
    handleDelete() {
      removeArticle(this.$props._id.$id).then((res) => {
        const { code, msg } = res && res.data;
        if (code === 0) {
          this.$message.success("Delete success");
          this.$emit("refreshData");
        } else {
          this.$message.error(msg);
        }
      });
    },
    goDetail() {
      if (this.member_status === "invited") return;
      if (this.$route.path === "/draft") {
        this.handlerCommand("Edit");
      } else {
        this.$router.push({
          path: "/articleDetail",
          query: {
            id: this.$props._id.$id,
            sectionType: this.$props.type,
            // 在此判断如果是admin账户 带type=submission类型跳转
            type: this.user_type === "admin" ? "submission" : "",
          },
        });
      }
    },
    handleInviteBtn(value) {
      this.inviteStatus = value;
      this.inviteDialog = true;
    },
    handleCancel() {
      this.inviteDialog = false;
    },
    async getInviteStatus(val) {
      const api =
        val === "join"
          ? joinArticle(this.member_id)
          : removeArticleMember(this.member_id);
      const res = await api;
      if (res.data.code === 0) {
        this.$message.success(
          val === "join"
            ? "Join co-authoring successful."
            : "Rejected successfully."
        );
        this.$emit("refreshData");
      }
    },
    handleConfirm() {
      this.inviteDialog = false;
      if (this.inviteStatus === "join") {
        this.checkSubscriptionStatus();
      } else {
        this.getInviteStatus("reject");
      }
    },
    async checkSubscriptionStatus() {
      const _type = this.$props.type === 1 ? "campus_pulses" : "other";
      const res = await checkSubscriptionStatus(getUserId(), _type);
      if (res.data) {
        this.getInviteStatus("join");
      } else {
        this.unsupportVisible = true;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.base-card-container {
  background-color: #fff;
  padding: 10px 20px;
  margin-bottom: 20px;

  .header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    user-select: none;

    .icon {
      height: 40px;
      width: 120px;
      background-repeat: no-repeat;
      background-size: contain;
      background-position: center center;
    }

    .operate {
      color: #1e3ec2;
      cursor: pointer;
      line-height: 40px;
    }
  }

  .content {
    display: flex;
    flex-direction: row;
    cursor: pointer;
    .pic {
      width: 200px;
      height: 300px;
      background-repeat: no-repeat;
      background-size: contain;
      background-position: center center;
    }

    .text-content {
      flex: 1;
      padding: 0 20px;
      display: flex;
      flex-direction: column;

      .title {
        font-size: 16px;
        font-weight: bold;
        color: black;
        padding: 20px 0;
      }

      .abstract {
        font-size: 14px;
        font-weight: bolder;
        color: black;
        height: 195px;
        overflow: hidden;
      }
      .invite-desc {
        font-size: 14px;
        color: #fd6963;
        height: 195px;
      }

      .time-content {
        width: 100%;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        color: #ccc;
        font-size: 12px;
        padding: 10px 0;
      }
      .footer-btn {
        display: flex;
        .btn {
          width: 80px;
        }
      }
    }
  }
}

.tags-container {
  display: flex;
  align-items: center;
  gap: 8px;
  height: 34px;
  overflow-x: auto;

  .tag {
    max-width: 200px;
    flex-shrink: 0;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    border-radius: 8px;
    font-size: 12px;
    padding: 8px 12px;

    .tag-text {
      max-width: 100%;
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 1;
      -webkit-box-orient: vertical;
    }
  }
}

.rotate90 {
  transform: rotate(90deg);
}
</style>
