<template>
  <el-dialog
    class="unsupport-dialog"
    :title="
      type === 'invite'
        ? 'Subscription Required for Co-authoring Article'
        : 'Subscription Required for Article Submission'
    "
    :visible.sync="visible"
    width="60%"
  >
    <div class="unsupport-dialog-content">
      <p class="label">
        {{
          type === "invite"
            ? "Your current subscription status does not support article submissions in this section. To proceed with co-authoring this article, please upgrade your subscription."
            : "Your current subscription status does not support article submissions in this section. To proceed with publishing your content, please upgrade your subscription."
        }}
      </p>
      <table
        width="500px"
        border="1"
        align="center"
        cellspacing="0"
        cellpadding="0"
      >
        <tr>
          <th width="30%">Basic Subscription</th>
          <th width="70%">Advanced Subscription</th>
        </tr>
        <tr>
          <td>
            <div>
              <span>Campus Pulses</span>
            </div>
          </td>
          <td>
            <div>
              <span>Campus Pulses</span>
              <span>Next Wave Business Review</span>
              <span>Literature and Art Review</span>
              <span>Comparative Thinking</span>
              <span>Intellect in Words</span>
              <span>Apex Stage</span>
              <span>History Perspectives</span>
              <span>Professor's Academic Challenge</span>
            </div>
          </td>
        </tr>
      </table>
    </div>

    <span slot="footer" class="unsupport-dialog-footer">
      <el-button type="primary" @click="handleConfirm">Confirm</el-button>
      <el-button type="primary" @click="handleUpgrade">Upgrade Now</el-button>
    </span>
  </el-dialog>
</template>
<script>
export default {
  name: "unsupportModal",

  props: {
    value: {
      type: Boolean,
    },
    type: {
      type: String,
      default: "",
    },
  },

  data() {
    return {
      visible: this.value,
      checked: false,
    };
  },

  watch: {
    value(n, o) {
      this.visible = n;
    },
    visible(n, o) {
      this.$emit("input", n);
    },
  },

  mounted() {},

  methods: {
    handleConfirm() {
      this.visible = false;
    },
    handleUpgrade() {
      this.$router.push({
        path: "home",
        query: {
          isSubscription: 1,
        },
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.unsupport-dialog {
  /deep/.el-dialog__header {
    text-align: center;
    .el-dialog__title {
      color: #eb7269;
      font-family: GravityBold;
    }
  }
  /deep/.el-dialog__footer {
    text-align: center;
  }
  &-content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    table {
      text-align: center;
    }
    table th {
      color: #eb7269;
      background: #fdf2f0;
      padding: 12px 0px;
    }
    table td {
      border: 1px solid #fdf2f0;
      border-top: none;
      padding: 10px 0px;
      span {
        display: block;
      }
    }
    .label {
      padding-bottom: 20px;
      word-break: break-word;
    }
  }
}
</style>
