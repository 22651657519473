<template>
  <div class="customer-info">
    <div class="header">
      <div class="avator" :style="{ backgroundImage: `url(${icon})` }"></div>
      <div class="info">
        <div class="name">{{ name }}</div>
        <div class="introduce">{{ school }}</div>
      </div>
    </div>
    <!-- <div class="content">
      <template v-for="(item, index) in followList">
        <div class="content-item" :key="index">
          <div class="count">
            {{ item.account }}
          </div>
          <div class="item">
            {{ item.name }}
          </div>
        </div>
      </template>
    </div> -->
    <div v-if="type != 'submission'">
      <el-button type="primary" class="premium-button" @click="goSubscription">
        {{ btnText }}
      </el-button>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";

export default {
  name: "customerInfo",
  data() {
    return {
      icon: "https://tse2-mm.cn.bing.net/th/id/OIP-C.7S5UR7TwZrebhm7zrEEXNQHaHa?rs=1&pid=ImgDetMain",
      followList: [
        { account: 124, name: "Followers" },
        { account: 124, name: "Following" },
        { account: 124, name: "Articles" },
      ],
    };
  },
  props: {
    type: String,
  },
  computed: {
    ...mapState(["userInfo", "subscribeInfo"]),
    name() {
      return (
        this.userInfo.name || JSON.parse(localStorage.getItem("userInfo")).name
      );
    },
    school() {
      return (
        this.userInfo.school ||
        JSON.parse(localStorage.getItem("userInfo")).school
      );
    },
    btnText() {
      return this.subscribeInfo.advancedStatus
        ? "Advanced Subscription"
        : this.subscribeInfo.basicStatus
        ? "Basic Subscription"
        : "Subscribe";
    },
  },
  methods: {
    goSubscription() {
      this.$router.push({
        path: "home",
        query: {
          isSubscription: 1,
        },
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.customer-info {
  background-color: #fff;
  padding: 5px 20px 25px;
  width: 260px;
  box-sizing: border-box;

  .header {
    display: flex;
    flex-direction: row;
    padding: 20px 0px;
    // border-bottom: 2px dotted #ccc;
    .avator {
      height: 50px;
      width: 50px;
      background-repeat: no-repeat;
      background-position: center;
      background-size: contain;
      border-radius: 50%;
    }

    .info {
      display: flex;
      flex-direction: column;
      margin-left: 14px;

      .name {
        flex: 1;
        font-weight: bolder;
        line-height: 25px;
      }

      .introduce {
        line-height: 25px;
        flex: 1;
        font-size: 12px;
      }
    }
  }

  .content {
    display: flex;
    flex-direction: row;
    padding: 20px 0 24px;
    &-item {
      display: flex;
      flex-direction: column;
      flex: 1;
      text-align: center;
      .count {
        font-weight: bold;
        margin-bottom: 10px;
        font-size: 18px;
      }
      .item {
        color: #8c8c8c;
        font-size: 12px;
      }
    }
  }

  .premium-button {
    width: 100%;
  }
}
</style>
